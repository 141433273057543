import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

import Slider from "react-slick"
var cldData = require("../../config.json")

const SecondChance = props => {
  var sfsImages = []

  // var cldImages = cldData.images.resources;
  // for (let i = 0; i < cldImages.length; i++) {
  //     if (cldImages[i].public_id.includes("DEV/Share_A_Smile_2021-"        sfsImages.push(cldImages[i].public_id);
  //     }
  // }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  }

  // console.log(sfsImages)

  sfsImages = [
    "OSSC/DEV/Share_A_Smile_2021-3232694",
    "OSSC/DEV/Share_A_Smile_2021-3232541",
    "OSSC/DEV/Share_A_Smile_2021-3232665",
    "OSSC/DEV/Share_A_Smile_2021-3232674",
    "OSSC/DEV/Share_A_Smile_2021-3232649",
    "OSSC/DEV/Share_A_Smile_2021-3232661",
    "OSSC/DEV/Share_A_Smile_2021-3232606"
  ]

  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          title="Learn about our Share A Smile program"
          description="The team at Oral Surgery & Dental Implant Specialists South Carolina is excited to announce its second Share A Smile recipient, Samuel."
        />

        <div className="joshua-tree-content second-chance second-chance-banner">
          <div
            className="banner-title-container has-text-centered"
            style={{ marginTop: "200px" }}
          >
            <h1>IT'S YOUR TIME TO</h1>
            <h1>SHARE A SMILE</h1>
          </div>
        </div>

        {/* <div className="columns">
                    <div className="column is-2"></div>
                    <div className="column smile-again-heading has-text-centered">
                        <h1>A CHANCE FOR A NEW SMILE</h1>
                    </div>
                    <div className="column is-2"></div>
                </div> */}

        <div
          className="columns is-centered soldiers-slider"
          style={{ marginTop: "48px" }}
        >
          <div className="column is-12">
            <Slider {...settings}>
              {sfsImages.map(image => {
                return <img src={`/${image}.jpg`} responsive />
              })}
            </Slider>
          </div>
        </div>

        <div className="columns" style={{ marginTop: "50px" }}>
          <div className="column is-4"></div>
          <div className="column smile-again-text-section">
            <h2 className="smile-again-subheading" style={{ marginTop: "50" }}>
              A Chance for a New Smile
            </h2>

            <p>
              The team at Oral Surgery & Dental Implant Specialists South
              Carolina is excited to announce its second Share A Smile
              recipient, Samuel.
            </p>

            <p>
              Samuel, who lives in Charleston and works in accounts receivable,
              has had dental issues his entire life. Dental issues run in his
              family, and as a teenager he was told that he would need dentures
              by the age of 40. Although he practices good oral hygiene, Samuel,
              now 27, has had multiple root canals, two crowns, and the rest of
              his teeth continue to decay.{" "}
            </p>

            <p>
              He has worked hard to overcome the depression his oral health has
              caused and has managed to bring many positive changes to his life,
              including moving from California to South Carolina, training dogs,
              and even self-publishing his first novel. He knows a{" "}
              <Link
                to="/dental-implants-in-mount-pleasant-sc/full-arch-restoration/"
                title="Learn more about full-arch restoration"
              >
                full-arch restoration
              </Link>{" "}
              provided by Oral Surgery & Dental Implant Specialists South
              Carolina will give him an even bigger boost, and he cannot wait to
              show off a new smile!
            </p>

            <h2 className="smile-again-subheading">
              About Full-Arch Restoration
            </h2>
            <p>
              Full-arch restoration is sometimes called <i>Same Day Smile</i>{" "}
              because individuals can achieve a brand-new smile in just one day.
              During the treatment, as few as four dental implants are used to
              support the prosthesis. Unlike traditional dentures, these dental
              implants eliminate the need for adhesives to keep the denture in
              place, preventing it from shifting and causing discomfort.
              Full-arch restoration helps restore the ability to eat, speak, and
              smile with confidence again!
            </p>
            <h3>Treatment Partners</h3>
            <ul>
              <li>
                Restorative Dentist: Christina Church, DMD from Daniel Island
                Dentistry
              </li>
              <li>Dental Lab: Absolute Dental Services</li>
              <li>Implant Provider: Straumann</li>
            </ul>

            <div className="smile-again-text-section smile-again-learn-more">
              <p>
                Follow Samuel’s journey to a healthy smile on{" "}
                <a
                  href="https://www.facebook.com/osscarolina/"
                  target="_blank"
                  title="Learn more on Facebook"
                >
                  Facebook
                </a>{" "}
                and{" "}
                <a
                  href="https://www.instagram.com/oss_carolina/"
                  target="_blank"
                  title="Learn more on Instagram"
                >
                  Instagram
                </a>
                !
              </p>

              <p>
                <i>
                  Learn more about{" "}
                  <Link
                    to="/dental-implants-in-mount-pleasant-sc/"
                    title="Learn more about dental implants"
                  >
                    dental implants
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/dental-implants-in-mount-pleasant-sc/full-arch-restoration/"
                    title="Learn more about full-arch restoration"
                  >
                    full-arch restoration
                  </Link>{" "}
                  to see how they can benefit your smile. Whether you need one
                  tooth or an entire arch of teeth replaced, our team has a
                  solution for you.
                </i>
              </p>
            </div>
          </div>

          <div className="column is-4"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SecondChance
